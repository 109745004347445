<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="sharingPack"
      :columns="columns">

      <template slot="search">
        <a-button  ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <pack-edit ref="editDrawer" @success="getList"></pack-edit>
  </a-card>
</template>
<script>
  import PackEdit from './PackEdit'
  export default {
    components: {
      PackEdit
    },
    data () {
      return {
        productList:[]
      }
    },
    created() {
      this.$get('product/all').then(suc=>{
        this.productList = suc.data

        let data = this.productList.map(item=>{
          return {
            test:item.productKey
          }
        })
        console.log(data)


      })
    },
    computed: {
      columns () {
        return [
          {
            title:"产品",
            dataIndex: 'productName',
            filterMultiple: false,
            filters: this.productList.map(item=>{
              return {
                text: item.name,
                value: item.productKey
              }
            })
          },
          {
            title: '时长（分钟）',
            dataIndex: 'duration',
          },
          {
            title: '现价（元）',
            dataIndex: 'price',
            customRender: (text, row, index) => {
              return text/100
            }
          },
          {
            title: '原价（元）',
            dataIndex: 'priceDel',
            customRender: (text, row, index) => {
              return text/100
            }
          },
          {
            title: '添加时间',
            dataIndex: 'createdAt'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.editDrawer.show()
      },
      edit (record) {
        this.$refs.editDrawer.show(record)
      },
      remove (record) {
        let that = this
        this.$confirm({
          title: '是否删除该记录?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('sharingPack/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },

      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
